import { Field } from 'formik';
import { Accordion, AccordionTab } from 'primereact/accordion';
import { useState } from 'react';
import { keyFilterConsultaRsql } from '../../../../../../../../../../Common';
import { Dropdown, Grid, If, InputDouble, InputField, TextArea } from '../../../../../../../../../../components';
import {
	optionsCnab,
	optionsDensidadeRemessa,
	optionsNossoNumeroPeloBanco,
	optionsRemessaReiniciaDiariamente,
	optionsSituacao,
	optionsTipoWebservice,
} from '../../Util';

function FieldsConvenio(props) {
	const {
		validarConvenioFieldsPorBanco,
		bancoSelecionado,
		setFieldValue,
		informacoesPermissoes,
		isMobile,
		isTablet,
		isLessHd,
		setFieldExcecao,
	} = props;

	const [activeIndex, setActiveIndex] = useState(null);

	function getOptionsTipoWebservice() {
		return optionsTipoWebservice.filter(e => e.banco === bancoSelecionado.value);
	}

	return (
		<Grid>
			<If test={validarConvenioFieldsPorBanco('cnab')}>
				<Field
					sm="12"
					md="3"
					lg="2"
					xl="2"
					component={Dropdown}
					label="CNAB"
					options={optionsCnab}
					name="cnab"
					showClear={false}
					onChange={e => setFieldValue('cnab', e.value)}
					obrigatorio
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('numero')}>
				<Field
					sm="12"
					md="3"
					lg="3"
					xl="3"
					component={InputField}
					label="Número"
					name="numero"
					keyfilter={keyFilterConsultaRsql}
					size={20}
					obrigatorio
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('descricao')}>
				<Field
					sm="12"
					md="6"
					lg="5"
					xl="5"
					component={InputField}
					label="Descrição"
					name="descricao"
					keyfilter={keyFilterConsultaRsql}
					size={60}
					obrigatorio
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('situacao')}>
				<Field
					sm="12"
					md="4"
					lg="2"
					xl="2"
					component={Dropdown}
					label="Situação"
					name="situacao"
					showClear={false}
					options={optionsSituacao}
					onChange={e => setFieldValue('situacao', e.value)}
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('numeroCarteira')}>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputField}
					label="Número carteira"
					name="numeroCarteira"
					onChange={e => setFieldValue('numeroCarteira', e.target.value)}
					keyfilter={keyFilterConsultaRsql}
					size={20}
					obrigatorio
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('codigoCarteira')}>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputField}
					label="Código carteira"
					name="codigoCarteira"
					onChange={e => setFieldValue('codigoCarteira', e.target.value)}
					keyfilter={keyFilterConsultaRsql}
					size={20}
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('numeroRemessaReiniciaDiariamente')}>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={Dropdown}
					label={
						isMobile || isTablet || isLessHd
							? 'Rem. reinicia diariamente'
							: 'Nº remessa reinicia diariamente'
					}
					name="numeroRemessaReiniciaDiariamente"
					options={optionsRemessaReiniciaDiariamente}
					onChange={e => setFieldValue('numeroRemessaReiniciaDiariamente', e.value)}
					showClear={false}
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('numeroRemessa')}>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputField}
					label="Número da remessa"
					name="numeroRemessa"
					type={'number'}
					keyfilter={keyFilterConsultaRsql}
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('densidadeRemessa')}>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={Dropdown}
					label="Densidade da remessa"
					name="densidadeRemessa"
					showClear={false}
					options={optionsDensidadeRemessa}
					onChange={e => setFieldValue('densidadeRemessa', e.value)}
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('tipoWebservice')}>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={Dropdown}
					label="Tipo webservice"
					name="tipoWebservice"
					showClear={false}
					options={getOptionsTipoWebservice()}
					onChange={e => {
						setFieldValue('tipoWebservice', e.value), setFieldExcecao('apiSecret');
					}}
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('numeroContrato')}>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputField}
					label="Número contrato"
					name="numeroContrato"
					keyfilter={keyFilterConsultaRsql}
					size={20}
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('versaoLayoutArquivo')}>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputField}
					label={isMobile || isTablet ? 'Versão layout' : 'Versão layout do arquivo'}
					name="versaoLayoutArquivo"
					onChange={e => setFieldValue('versaoLayoutArquivo', e.target.value)}
					keyfilter={keyFilterConsultaRsql}
					size={3}
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('numeroRemessaReiniciaDiariamente')}>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={Dropdown}
					label="Nosso número pelo banco"
					name="nossoNumeroPeloBanco"
					options={optionsNossoNumeroPeloBanco}
					onChange={e => setFieldValue('nossoNumeroPeloBanco', e.value)}
					showClear={false}
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('nossoNumero')}>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputField}
					label="Nosso número"
					name="nossoNumero"
					type={'number'}
					keyfilter={keyFilterConsultaRsql}
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('percentualJuros')}>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputDouble}
					label="Percentual juros"
					name="percentualJuros"
					onChange={e => setFieldValue('percentualJuros', e.target.value)}
					keyfilter={keyFilterConsultaRsql}
					decimalScale={2}
					prefix=""
					suffix="%"
					maxValue={100}
					placeholder="0,00 %"
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('percentualMulta')}>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputDouble}
					label="Percentual multa"
					name="percentualMulta"
					onChange={e => setFieldValue('percentualMulta', e.target.value)}
					keyfilter={keyFilterConsultaRsql}
					decimalScale={2}
					prefix=""
					suffix="%"
					maxValue={100}
					placeholder="0,00 %"
					{...informacoesPermissoes}
				/>
			</If>
			<If test={validarConvenioFieldsPorBanco('percentualDesconto')}>
				<Field
					sm="12"
					md="4"
					lg="4"
					xl="4"
					component={InputDouble}
					label="Percentual desconto"
					name="percentualDesconto"
					onChange={e => setFieldValue('percentualDesconto', e.target.value)}
					keyfilter={keyFilterConsultaRsql}
					decimalScale={2}
					prefix=""
					suffix="%"
					maxValue={100}
					placeholder="0,00 %"
					{...informacoesPermissoes}
				/>
			</If>
			<Accordion
				className="tab-accordion-lite"
				activeIndex={activeIndex}
				onTabChange={event => setActiveIndex(event.index)}
				style={{ width: '100%', marginBottom: '16px' }}
			>
				<AccordionTab header="Mensagens">
					<Grid>
						<Field
							sm="12"
							md="12"
							lg="6"
							xl="6"
							component={TextArea}
							label="Mensagem 01"
							name="mensagem01"
							maxLength={80}
							rows={3}
							placeholder="Escreva aqui a sua mensagem"
							onChange={event => setFieldValue('mensagem01', event.target.value)}
							{...informacoesPermissoes}
						/>
						<Field
							sm="12"
							md="12"
							lg="6"
							xl="6"
							component={TextArea}
							label="Mensagem 02"
							name="mensagem02"
							maxLength={80}
							rows={3}
							placeholder="Escreva aqui a sua mensagem"
							onChange={event => setFieldValue('mensagem02', event.target.value)}
							{...informacoesPermissoes}
						/>
					</Grid>
				</AccordionTab>
			</Accordion>
		</Grid>
	);
}

export default FieldsConvenio;
