import { services } from '../../../../Common/Constantes/api';
import { del, get, exibirToast } from '../../../../Common/Requisicoes';

export async function asyncDeletePlano(planoId, onSuccess, onError) {
	await del(`${services.GESTOR}/v1/administracao/planos/${planoId}`, null, exibirToast(onSuccess, 'Operação realizada com sucesso'), onError);
}

export async function asyncGetPesquisaPlanos(url, onSuccess, onError) {
	await get(url, null, exibirToast(onSuccess), onError);
}
